<template>
  <div class="box-card">
    <h3>活动信息</h3>
    <div class="show_list">
      <div class="rowList">
        活动名称：<span>{{ activeInfo.name }}</span>
      </div>
      <div class="rowList">
        活动ID：<span>{{ activeInfo.id }}</span>
      </div>

      <div class="rowList">
        活动状态：<span>{{
          $dict.activeStatus | dictFormat(activeInfo.status)
        }}</span>
      </div>
      <div class="rowList">
        活动开始时间：<span>{{ activeInfo.startTime }}</span>
      </div>
      <div class="rowList">
        活动结束时间：<span>{{ activeInfo.endTime }}</span>
      </div>

      <div class="rowList">
        活动时间：<span>{{
          $dict.activeType | dictFormat(activeInfo.type)
        }}</span>
      </div>
      <div class="rowList">
        <BaseEditor2
          ref="getDetail"
          :value="formInfo.details"
          placeholder="请输入商品详情"
          class="BaseEditor"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activeInfo"],
  name: "activeInfo",
  data() {
    return {
      formInfo: { ...this.activeInfo },
    };
  },
};
</script>
<style scoped lang='scss'>
.box-card {
  width: 500px;
  border-right:1px solid #f1f1f1 ;
}
.rowList {
  margin: 20px 0;
}
.BaseEditor {
  width: 400px;
}
</style>
