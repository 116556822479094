<template>
  <!-- 表格 -->
  <el-table
    :data="dataList"
    v-loading="dataListLoading"
    style="width: 100%"

    highlight-current-row
  >
    <el-table-column
      v-if="type == 'add'"
      prop="productId"
      label="商品ID"
      align="center"
    >
    </el-table-column>
    <el-table-column v-else prop="product" label="商品ID" align="center">
    </el-table-column>
    <el-table-column prop="productName" label="商品名称" align="center">
    </el-table-column>
    <el-table-column
      prop="productRepositoryId"
      v-if="type == 'add'"
      label="规格ID"
    >
    </el-table-column>
    <el-table-column prop="productId" v-else label="规格ID" align="center">
    </el-table-column>
    <el-table-column prop="productDetailGroupName" label="规格" align="center">
    </el-table-column>
    <el-table-column v-if="type == 'add'" label="商品主图" align="center">
      <template slot-scope="scope">
        <img width="80" height="80" :src="scope.row.imageUrl" alt="" />
      </template>
    </el-table-column>
    <el-table-column v-else label="商品主图" align="center">
      <template slot-scope="scope">
        <img width="80" height="80" :src="scope.row.productImg" alt="" />
      </template>
    </el-table-column>

    <el-table-column v-if="type == 'add'" prop="count" label="库存" align="center">
    </el-table-column>

    <el-table-column v-if="type == 'add'" label="参与秒杀数量" align="center">
      <template slot-scope="scope">
        <el-input class="edit" size="mini" v-model="scope.row.seckillQuantity">
        </el-input>
      </template>
    </el-table-column>

    <el-table-column v-else label="参与秒杀数量" align="center">
      <template slot-scope="scope">
        {{ scope.row.seckillQuantity }}
      </template>
    </el-table-column>

    <el-table-column
      prop="price"
      v-if="type == 'add'"
      label="零售价（元）"
      align="center"
    >
    </el-table-column>
    <el-table-column v-else label="零售价（元）" align="center">
      <template slot-scope="scope">
        {{ scope.row.retailPrice / 100 }}
      </template>
    </el-table-column>
    <el-table-column v-if="type == 'add'" label="秒杀价（元）" align="center">
      <template slot-scope="scope">
        <el-input class="edit" size="mini" v-model="scope.row.seckillPrice">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column v-else label="秒杀价（元）" align="center">
      <template slot-scope="scope">
        {{ scope.row.seckillPrice / 100 }}
      </template>
    </el-table-column>

    <el-table-column
      label="操作"
      v-if="type == 'add'"
      align="center"
      width="280px"
    >
      <template slot-scope="scope">
        <el-button
          size="small"
          type="primary"
          @click="deleteShop(scope.row.productRepositoryId)"
          >删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'dataList',
  data() {
    return {};
  },
  methods: {
    deleteShop(id) {
      this.$emit('deleteGoods', id);
    },
  },
  props: {
    dataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dataListLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'add',
    },
  },
};
</script>

<style scoped>

</style>
